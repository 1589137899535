body, html {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    background-color: #F4F4F4;
    color: #333;
    height: 100%;
}

body {
    overflow-y: auto;
    background-color: #fff;
}
*{
    box-sizing: border-box;  
}
#root{
    background-color: #F9E3E0;
    /* height: 100%; */
}
.container {
    max-width: 414px;
    margin: 0 auto;
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    position: relative;
    overflow: hidden;
}
header {
    background-color: #F9E3E0;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-title {
    font-size: 18px;
    font-weight: bold;
}
.header-actions {
    display: flex;
    gap: 15px;
}
.icon {
    width: 24px;
    height: 24px;
}
.note-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px 20px;
}
.note-item {
    background-color: #FFF8F7;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.note-content {
    flex-grow: 1;
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
}
.note-meta {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
}
.qr-code {
    width: 60px;
    height: 60px;
    background-color: #ddd;
    flex-shrink: 0;
}
.add-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 56px;
    height: 56px;
    background-color: #F9776F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px rgba(249, 119, 111, 0.3);
}

.edit-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #fff;
}
.edit-section {
    margin-bottom: 20px;
    
}

.edit-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    height: 150px;
    resize: vertical;
    
}
.image-upload-section {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px dashed #ccc;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
}
.image-upload-icon {
    width: 24px;
    height: 24px;
    fill: #F9776F;
}
.image-upload-text {
    font-size: 14px;
    color: #666;
}
.location-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.location-info {
    display: flex;
    align-items: center;
    gap: 10px;
}
.location-icon {
    width: 24px;
    height: 24px;
    fill: #F9776F;
}
#map {
    width: 100%;
    height: 200px;
    border-radius: 4px;
    overflow: hidden;
}
.save-button {
    background-color: #F9776F;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: auto;
}
#imageUpload {
    display: none;
}
.location-note-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 10px;
}
.image-preview-section {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 0;
    margin-bottom: 20px;
}

.image-container {
    width: calc(33.33% - 10px);
    aspect-ratio: 1/1;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    border: 2px solid #F9E3E0;
    overflow: visible;
}

.image-remove {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 24px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    cursor: pointer;
    opacity: 1;
    transition: background-color 0.2s;
    z-index: 1;
}

.image-remove:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.image-viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    animation: fade-in 0.3s ease-in-out;
    user-select: none;
}

.viewer-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    animation: zoom-in 0.3s ease-in-out;
    pointer-events: none;
}

.prev-arrow,
.next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s;
}

.prev-arrow:hover,
.next-arrow:hover {
    background-color: rgba(249, 119, 111, 0.8);
}

.prev-arrow {
    left: 20px;
}

.next-arrow {
    right: 20px;
}

.prev-arrow::before,
.next-arrow::before {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    display: block;
}

.prev-arrow::before {
    transform: rotate(-135deg);
    margin-left: 4px;
}

.next-arrow::before {
    transform: rotate(45deg);
    margin-right: 4px;
}

.close-viewer {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: white;
    cursor: pointer;
    user-select: none;
}

.settings-menu {
    position: absolute;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0,0,0,0.1);
    transition: right 0.3s ease-in-out;
    z-index: 20;
}
.settings-menu.active {
    right: 0;
}
.settings-header {
    background-color: #F9E3E0;
    padding: 15px 20px;
    font-size: 18px;
    font-weight: bold;
}
.settings-list {
    padding: 20px;
}
.settings-item {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.settings-item:last-child {
    border-bottom: none;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
    z-index: 15;
}
.overlay.active {
    opacity: 1;
    visibility: visible;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes zoom-in {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(1);
    }
}